import { ApolloClient, InMemoryCache } from '@apollo/client'

export const getClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          keyFields: ['id']
        }
      }
    }),
    uri: '/graphql'
  })
}

export const client = getClient()
