import { graphql } from '@local/grouter'

export const IntercomSessionData = graphql(`
  query IntercomSessionData($clientType: IntercomClientType!) {
    intercomSessionData(intercomClientType: $clientType) {
      intercomUserHash
      user {
        id
        email
        name {
          first
          chosen
          last
        }
      }
    }
  }
`)
