import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'
const APP_ID_TEST = 'hd9ex4f9'
const APP_ID_PROD = 'eblpqpe0'
/**
 * getAppId
 *
 */
export function getAppId() {
  const env = getCurrentEnvironment()
  if (env === ToastEnvironment.PROD) {
    return APP_ID_PROD
  }
  return APP_ID_TEST
}

export const APP_ID = getAppId()
