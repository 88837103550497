import * as React from 'react'

import { ApolloProvider } from '@apollo/client'
import { getClient } from '@local/api'
import { IntercomChat } from '@local/intercom-chat'
import { useFeature } from '@toasttab/ec-features'
import { useUserCanViewChatbot } from '@local/user-util'

export function App() {
  const [client] = React.useState(() => getClient())
  const isPayrollChatbot = useFeature('ec-pcard-payroll-chatbot', false)
  const userCanViewChatbot = useUserCanViewChatbot()
  return isPayrollChatbot && userCanViewChatbot ? (
    <ApolloProvider client={client}>
      <IntercomChat />
    </ApolloProvider>
  ) : null
}
