import { useUser } from '@toasttab/ec-session'

/**
 * useUserCanViewChatbot
 *
 */
export const useUserCanViewChatbot = (): boolean => {
  const user = useUser()
  return (
    user.isPureUserHRPlusOrAbove ||
    user.isUserHrPlusOrDerivative ||
    user.isUserManager ||
    user.isUserSuperAdmin ||
    user.isUserSuperAdminLight ||
    false
  )
}
